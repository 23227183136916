@tailwind base;

@layer base {
  html {
    @apply scroll-smooth text-gray-900;
  }
  input,
  textarea {
    @apply placeholder:text-gray-400;
  }
}

@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-none {
    font-size: 0;
  }

  .home-page-hero-gradient {
    background-color: transparent;
    background-image: radial-gradient(
        at 0% 0%,
        rgb(184, 205, 249) 0,
        rgba(255, 255, 255, 0) 60%
      ),
      radial-gradient(
        at 100% 0%,
        rgb(219, 217, 239) 0,
        rgba(255, 255, 255, 0) 45%
      );
  }

  .common-page-section-gradient {
    background-color: white;
    background-image: radial-gradient(
        at 0% 0%,
        rgb(167, 193, 222) 0,
        rgba(255, 255, 255, 0) 80%
      ),
      radial-gradient(
        at 100% 40%,
        rgb(243, 220, 228) 0,
        rgba(255, 255, 255, 0) 75%
      ),
      radial-gradient(
        at 45% 70%,
        rgb(207, 206, 231) 0,
        rgba(255, 255, 255, 0) 40%
      );
  }

  .contact-page-hero-gradient {
    background: linear-gradient(
      58deg,
      rgb(255, 236, 240) 0%,
      rgb(217, 222, 241) 59%,
      rgb(205, 210, 232) 100%
    );
  }

  .partners-page-hero-gradient {
    background: transparent
      linear-gradient(
        60deg,
        rgb(253, 232, 236) 0%,
        rgb(221, 223, 238) 51%,
        rgb(215, 210, 230) 100%
      );
  }

  .social-impact-gradient {
    background-color: white;
    background-image: radial-gradient(
        at 0% 0%,
        white 0,
        rgba(255, 255, 255, 0) 50%
      ),
      radial-gradient(
        at 0% 100%,
        rgb(243, 220, 228) 0,
        rgba(255, 255, 255, 0) 40%
      ),
      radial-gradient(
        at 0% 0%,
        rgb(167, 193, 222) 0,
        rgba(255, 255, 255, 0) 80%
      ),
      radial-gradient(
        at 100% 40%,
        rgb(243, 220, 228) 0,
        rgba(255, 255, 255, 0) 75%
      ),
      radial-gradient(
        at 45% 70%,
        rgb(207, 206, 231) 0,
        rgba(255, 255, 255, 0) 40%
      );
  }

  .insurance-page-hero-gradient {
    background: radial-gradient(
        67.65% 48.92% at 72.73% 14.25%,
        rgba(215, 216, 234, 0.5) 0%,
        rgba(215, 213, 233, 0) 100%
      ),
      radial-gradient(
        50.27% 50.27% at 14.77% 32.26%,
        rgba(254, 235, 239, 0.5) 0%,
        rgba(252, 236, 239, 0) 100%,
        rgba(252, 236, 239, 0) 100%
      ),
      linear-gradient(
        226.55deg,
        rgba(222, 221, 238, 0.5) 8.4%,
        rgba(248, 248, 249, 0) 95.8%
      ),
      linear-gradient(
        208.11deg,
        rgba(194, 210, 247, 0.5) 8.63%,
        rgba(202, 211, 232, 0) 87.1%
      ),
      linear-gradient(190.8deg, #dcdbef 5.83%, #dadfec 61.47%, #fcfcfc 91.99%);
  }
  .careers-page-hero-gradient {
    background:
      radial-gradient(
          54.14% 46.77% at 43.41% 25%,
          rgba(189, 208, 249, 0.5) 0%,
          rgba(189, 208, 249, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          73.32% 76.61% at 73.18% 23.39%,
          rgba(201, 214, 247, 0.5) 0%,
          rgba(230, 229, 244, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          38.67% 35.22% at 42.05% 47.04%,
          rgba(215, 215, 232, 0.5) 0%,
          rgba(223, 216, 234, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      linear-gradient(
        270deg,
        rgba(243, 234, 242, 0.5) 2.5%,
        rgba(189, 208, 249, 0) 100%
      ),
      linear-gradient(
        90deg,
        rgba(206, 219, 237, 0.5) 0%,
        rgba(234, 232, 239, 0.25) 102.95%
      ),
      linear-gradient(
        180deg,
        rgba(254, 235, 239, 0.5) 0%,
        rgba(254, 235, 239, 0) 100%
      ),
      linear-gradient(214.97deg, #bdd0f9 0%, #e5e6f3 38.47%, #fcfcfc 79.42%);
  }
}

progress {
  @apply h-2 rounded-lg border-0 bg-gray-200;
}
progress::-webkit-progress-bar {
  @apply h-2 rounded-lg border-0 bg-gray-200;
}
progress::-webkit-progress-value {
  @apply bg-success-700 relative h-2 rounded-lg border-0;
}
progress::-moz-progress-bar {
  @apply h-2 rounded-lg border-0 bg-gray-200;
}

/* keen slider on about us page */
.navigation-wrapper {
  @apply relative;
}
.dots {
  @apply flex justify-start;
}
.dot {
  @apply mx-2.5 h-2 w-2 cursor-pointer rounded-full bg-gray-300 p-1;
}
.dot:focus {
  @apply outline-none;
}
.dot.active {
  @apply bg-primary-700 ring-primary-100 ring-4;
}

/*
* Hubspot form
*/
.hubspot-subscribe-form h2 {
  @apply mb-2 text-base font-bold;
}

.hubspot-subscribe-form p {
  @apply mb-6 text-base text-gray-700;
}

.hubspot-subscribe-form label {
  @apply sr-only;
}

.hubspot-subscribe-form input {
  @apply focus:ring-primary-700 mb-2 w-full rounded border border-gray-300 outline-none placeholder:text-base placeholder:text-gray-500 focus:border-gray-300 focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:placeholder-gray-400 lg:w-96;
}

.hubspot-subscribe-form input[type='submit'] {
  @apply text-primary-900 border-primary-900 hover:bg-primary-50/50 focus-visible:bg-primary-50/50 inline-flex w-auto cursor-pointer rounded border px-6 py-2 font-medium tracking-[0.0625rem] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)] disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-gray-400 disabled:hover:bg-white disabled:active:shadow-none;
}
